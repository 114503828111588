
RailsJSPrompt = {
  prompt: function(l){
    var link = $(l);

    var href = link.attr('href'),
    method = link.data('method'),
    target = link.attr('href'),
    csrfToken = $('meta[name=csrf-token]').attr('content'),
    csrfParam = $('meta[name=csrf-param]').attr('content'),
    form = $('<form method="post" action="' + href + '"></form>'),
    metadataInput = '<input name="_method" value="' + method + '" type="hidden" />',
    msg = link.data('prompt'),
    value = prompt(msg),
    paramName = link.data('param-name') || 'prompt-value',
    promptParamInput = '<input name="' + paramName + '" value="' + value + '" type="hidden" />';

    if (csrfParam !== undefined && csrfToken !== undefined) {
      metadataInput += '<input name="' + csrfParam + '" value="' + csrfToken + '" type="hidden" />';
    }

    form.hide().append(metadataInput).append(promptParamInput).appendTo('body');
    form.submit();
  }
}

$(document).on('click', 'a[data-prompt]', function(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  RailsJSPrompt.prompt(this);
});