
$(function() {
  "use strict";


// metismenu

$(function() {

  $('#menu').metisMenu();
  
});


$(".search-btn-mobile").on("click", function(){
  $(".search-bar").addClass("full-search-bar");
}); 

$(".search-arrow-back").on("click", function(){
  $(".search-bar").removeClass("full-search-bar");
});

$(".overlay").on("click", function(){
  $("#wrapper").removeClass("toggled");
});

$(".close-btn").on("click", function(){
  $("#wrapper").removeClass("toggled");
});


// toggle menu button

$(".toggle-menu").click(function () {
  if ($("#wrapper").hasClass("toggled")) {
    $("#wrapper").removeClass("toggled");
    $("#sidebar-wrapper").unbind( "hover");
  } else {
    $("#wrapper").addClass("toggled");
    $("#sidebar-wrapper").hover(
      function () {
        $("#wrapper").addClass("sidebar-hovered");
      },
      function () {
        $("#wrapper").removeClass("sidebar-hovered");
      }
      )

  }
});



// === sidebar menu activation js

$(function() {
  var currentURL = window.location.href;
  $(".metismenu li a").each(function() {
    if (currentURL.startsWith(this.href)) {
      $(this).addClass("").parent().addClass("mm-active");
      var parent = $(this).parent();
      while (parent.is("li")) {
        parent = parent.parent().addClass("mm-show").parent().addClass("mm-active");
      }
    }
  });
}),      


/* Top Header */

$(document).ready(function(){ 
  $(window).on("scroll", function(){ 
    if ($(this).scrollTop() > 60) { 
      $('.topbar-nav .navbar').addClass('bg-dark'); 
    } else { 
      $('.topbar-nav .navbar').removeClass('bg-dark'); 
    } 
  });

});


/* Back To Top */

$(document).ready(function(){ 
  $(window).on("scroll", function(){ 
    if ($(this).scrollTop() > 300) { 
      $('.back-to-top').fadeIn(); 
    } else { 
      $('.back-to-top').fadeOut(); 
    } 
  }); 

  $('.back-to-top').on("click", function(){ 
    $("html, body").animate({ scrollTop: 0 }, 600); 
    return false; 
  }); 
});    


// page loader

$(window).on('load', function(){

  $('#pageloader-overlay').fadeOut(1000);

})  


$(function () {
  $('[data-toggle="popover"]').popover()
})


$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})


   // theme setting
   $(".switcher-icon").on("click", function(e) {
    e.preventDefault();
    $(".right-sidebar").toggleClass("right-toggled");
  });

  $('#theme1').click(theme1);
  $('#theme2').click(theme2);
  $('#theme3').click(theme3);
  $('#theme4').click(theme4);
  $('#theme5').click(theme5);
  $('#theme6').click(theme6);
  $('#theme7').click(theme7);
  $('#theme8').click(theme8);
  $('#theme9').click(theme9);
  $('#theme10').click(theme10);
  $('#theme11').click(theme11);
  $('#theme12').click(theme12);
  $('#theme13').click(theme13);
  $('#theme14').click(theme14);
  $('#theme15').click(theme15);

  function theme1() {
    $('body').attr('class', 'bg-theme bg-theme1');
  }

  function theme2() {
    $('body').attr('class', 'bg-theme bg-theme2');
  }

  function theme3() {
    $('body').attr('class', 'bg-theme bg-theme3');
  }

  function theme4() {
    $('body').attr('class', 'bg-theme bg-theme4');
  }

  function theme5() {
    $('body').attr('class', 'bg-theme bg-theme5');
  }

  function theme6() {
    $('body').attr('class', 'bg-theme bg-theme6');
  }

  function theme7() {
    $('body').attr('class', 'bg-theme bg-theme7');
  }

  function theme8() {
    $('body').attr('class', 'bg-theme bg-theme8');
  }

  function theme9() {
    $('body').attr('class', 'bg-theme bg-theme9');
  }

  function theme10() {
    $('body').attr('class', 'bg-theme bg-theme10');
  }

  function theme11() {
    $('body').attr('class', 'bg-theme bg-theme11');
  }

  function theme12() {
    $('body').attr('class', 'bg-theme bg-theme12');
  }

  function theme13() {
    $('body').attr('class', 'bg-theme bg-theme13');
  }

  function theme14() {
    $('body').attr('class', 'bg-theme bg-theme14');
  }

  function theme15() {
    $('body').attr('class', 'bg-theme bg-theme15');
  }

});