require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

//import 'jquery'

window.jQuery = $
window.$ = $

import "lodash";

import axios from 'axios';
import "bootstrap";

require("local-time").start()
import 'data-confirm-modal'

import '../javascript/theme_transparent'

//import "@fortawesome/fontawesome-free/css/all"

// 告訴 Trix 當 attribute 是 subHeading 時，要產生 <h2> 的標籤
// Trix.config.textAttributes.subHeading = {
//   inheritable: true,
//   tagName: 'h2',
// };

// trix-initialize 時執行 trixInit
// document.addEventListener('turbolinks:load', function() {
//   document.addEventListener('trix-initialize', trixInit, false);
// });


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
