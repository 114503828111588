//https://github.com/gorails-screencasts/uppy-and-activestorage-rails
const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const ActiveStorageUpload = require('uppy-activestorage-upload')

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')


document.addEventListener("DOMContentLoaded", function() {
	document.querySelectorAll('[data-uppy]').forEach(element => setupUppy(element))
})

function setupUppy(element) {
	let trigger = element.querySelector('[data-behavior="uppy-trigger"]')
	let form = element.closest('form')
	let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
	let field_name = element.dataset.uppy
	let field_builder = element.dataset.form_builder
	let complete_ount = 0

	trigger.addEventListener("click", (event) => event.preventDefault())

	let uppy = Uppy({
		autoProceed: true,
		allowMultipleUploads: true,
		logger: Uppy.debugLogger
	})

	uppy.use(ActiveStorageUpload, {
		directUploadUrl: direct_upload_url
	})

	uppy.use(Dashboard, {
		trigger: trigger,
		closeAfterFinish: true,

    autoProceed: true,
    showProgressDetails: true,
    closeModalOnClickOutside: true,
    hideProgressAfterFinish: true,
    restrictions: {
      allowedFileTypes: ["image/*", "video/*"]
    },
	})

	uppy.on('complete', (result) => {
    // Rails.ajax
    // or show a preview:

  	//form_dom.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

    result.successful.forEach(file => {
    	let obj = trigger
    	let time = new Date().getTime() + "" + complete_ount;
    	let regexp = new RegExp(obj.dataset.id, 'g')
    	let form = new DOMParser().parseFromString(obj.dataset.form.replace(regexp, time), 'text/html').body.firstChild;
    	let form_dom = document.querySelector(obj.dataset.target).appendChild(form)
    	let remove_object_btn = form_dom.querySelector(".remove-object-btn")

    	appendUploadedFile(form_dom, file)
    	setPreview(form_dom, file)

    	addRemoveEvent(remove_object_btn)
    	complete_ount += 1
    })

    //uppy.reset()
  })
}

function appendUploadedFile(element, file) {
	let signed_id = element.querySelector('._file')
	let name = element.querySelector('._name')

	signed_id.value = file.response.signed_id
	name.value = file.response.filename


}

function setPreview(element, file) {
	let preview = element.querySelector('.preview')
	if (preview) {
		let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
		preview.src = src
	}
}

let addRemoveEvent = function(i){
	i.addEventListener('click',
		function(event){
			let obj = event.target
			let obj_parent = obj.closest(obj.dataset.target)
			let destory_input = obj_parent.querySelector("input[data-name='_destroy']")
			destory_input.value = true
			obj_parent.style.setProperty("display", "none")
			event.stopPropagation()
		},
		false)
}


